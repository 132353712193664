import React from "react"

const TermsOfService = () => {
  return (
    <div className="container">
      <br /><br />
      <h1>Condiciones del Servicio de la Plataforma Orion Capital</h1>
      <br />
      <p><em>Última actualización: 1/11/2023:12:44am</em></p>
      <br />
      <h2>Introducción</h2>
      <p>
        Al utilizar los servicios ofrecidos por Plataforma Orion Capital, aceptas cumplir con estas Condiciones. Te recomendamos que las leas detenidamente. Si no estás de acuerdo con alguna de las siguientes condiciones, te pedimos que no utilices nuestros servicios.
      </p>

      <h2>Uso de los Servicios</h2>
      <ol>
        <li>
          <strong>Registro de Cuenta</strong>: Para utilizar ciertas características de Plataforma Orion Capital, es posible que debas crear una cuenta. Aceptas proporcionar información precisa y actualizada al registrarte.
        </li>
        <li>
          <strong>Uso Adecuado</strong>: No debes utilizar nuestros servicios de manera que pueda causar daño, interferencia o interrupción en la operación de la plataforma, ni en las cuentas de otros usuarios.
        </li>
        <li>
          <strong>Privacidad</strong>: Debes respetar nuestra Política de Privacidad, que regula el uso de tu información personal en nuestra plataforma.
        </li>
      </ol>

      <h2>Propiedad Intelectual</h2>
      <ol>
        <li>
          <strong>Derechos de Propiedad</strong>: Plataforma Orion Capital es propiedad de [Nombre de la Compañía] y está protegida por las leyes de derechos de autor y marcas registradas. No tienes permiso para copiar, modificar o distribuir el contenido de la plataforma sin autorización.
        </li>
      </ol>

      <h2>Responsabilidades y Limitaciones</h2>
      <ol>
        <li>
          <strong>Exclusión de Garantías</strong>: No ofrecemos garantías de ningún tipo con respecto a la precisión o disponibilidad de los servicios. Utilizas los servicios bajo tu propio riesgo.
        </li>
        <li>
          <strong>Limitación de Responsabilidad</strong>: Plataforma Orion Capital no será responsable de ningún daño indirecto, incidental, especial, consecuente o punitivo relacionado con el uso de nuestros servicios.
        </li>
      </ol>

      <h2>Terminación</h2>
      <ol>
        <li>
          <strong>Terminación del Acuerdo</strong>: Podemos terminar o suspender tu cuenta y acceso a la plataforma si consideramos que has violado estas Condiciones.
        </li>
      </ol>

      <h2>Cambios en las Condiciones</h2>
      <ol>
        <li>
          <strong>Modificaciones</strong>: Nos reservamos el derecho de modificar estas Condiciones en cualquier momento. Te notificaremos de los cambios y la fecha de entrada en vigor de las nuevas condiciones.
        </li>
      </ol>

      <h2>Contacto</h2>
      <p>
        Si tienes alguna pregunta o inquietud sobre estas Condiciones del Servicio, no dudes en ponerte en contacto con nosotros en [correo electrónico de contacto].
      </p>
    </div>
  )
}

export default TermsOfService
