import React from "react"
import { Link } from 'react-router-dom'
import ImgHome from "../images/home.svg"
import Projects from "../images/projects.svg"
import Investment from "../images/investment.svg"
import Rentals from "../images/rentals.svg"
import Marketplace from "../images/marketplace.svg"
import User from "../images/user.png"
import Logo from "../images/logo-letme-c.svg"

const Nav = ({ activeLink }) => {
  return (
    <header>
  <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
    <div className="container-fluid">
      <img className="logo-w img-fluid" src={Logo} alt="Logo" />
      <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="container navbar-collapse collapse" id="navbarCollapse">
        <ul className="navbar-nav me-auto mb-2 mb-md-0">
          <li className="nav-item">
           <Link className={(activeLink === 'Inicio'  ? 'nav-link active' : 'nav-link')} aria-current="page" to="/dashboard"><img className="img-nav" src={ImgHome} alt="Home" /> Inicio</Link>
          </li>
         
          {/*<li className="nav-item">
            <Link className={(activeLink === 'MyInvestments'  ? 'nav-link active' : 'nav-link')} to="/my-investments"><img className="img-nav" src={Investment} alt="Home" /> Mis Inversiones </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link disabled" aria-disabled="true"><img className="img-nav" src={Rentals} alt="Home" /> Alquileres</Link>
          </li>*/}
          <li className="nav-item">
            <a href="http://proyectos.orioncapital.com.py" className="nav-link"><img className="img-nav" src={Projects} alt="Home" /> Proyectos Disponibles </a>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/marketplace"><img className="img-nav" src={Marketplace} alt="marketplace" /> Marketplace </Link>
          </li>
        </ul>
        
        
        <form className="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3" role="search">
          <input type="search" className="form-control" placeholder="Buscar..." aria-label="Search" />
        </form>
        <div className="dropdown text-end">
          <Link to="/available-projects" className="nav-link d-block link-body-emphasis text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          &nbsp; <img src={User} alt="mdo" width="32" height="32" className="rounded-circle" /> &nbsp; Carlos González &nbsp;
          </Link>
          <ul className="dropdown-menu text-small">
            <li><Link className="dropdown-item" to="/available-projects">Mi Perfil</Link></li>
            <li><hr className="dropdown-divider" /></li>
            <li><Link className="dropdown-item" to="/">Salir</Link></li>
          </ul>
      </div>

      </div>
      
      
     
      
    </div>
  </nav>
</header>
  )
}

export default Nav
