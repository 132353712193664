import React from "react"
import Nav from "./components/Nav"
import { Link } from "react-router-dom"
import Img1 from './images/img1-2.jpg'
import Img2 from './images/img2-2.jpg'
import Img3 from './images/img3-2.jpg'

import AvailableProjectsDetails from "./AvailableProjectsDetails"

const Marketplace = () => {
  return (
    <>
    <Nav activeLink={"Marketplace"}/>
    <br /><br /><br /><br />
    <div className="container">
      <div className="row enc">
      <div className="col-md-4">
        <h4>Marketplace</h4> 
      </div>
      <div className="col-md-4">
        
      </div>
      <div className="col-md-4">
        <span><strong>Dashboard</strong> &gt; Marketplace</span>
      </div>
      </div>


    <div className="row">
  




    <div className='col-md-4 l-proyectos'>
 
  <div class="divcard">
	<div>
    <img src={Img2} alt="1" className='img'/>
	</div>
 <div class="cardinfo">
  <div class="div-2">
    <div class="div-3">Apartamento en Jara Sky Towers</div>
    <div class="div-4">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/24ccf17c-6f89-4628-adf0-29a22c903b98?apiKey=859fcd62e1a949e59d3a0240a7b39fbc&"
        class="img-2"
      />
      <div class="div-5">Av. Pitiantuta, 77 - Bo. Jara, Asunción</div>
    </div>
    <hr />
    <div className='div-5-1'>
    <strong>Ofrecido Por:</strong> Juan Gimenez
    </div>
    <div class="participacion">
    <span>5%</span>
    <div>Participación</div>
    </div>
    
  </div>
     <a className='btn btn-primary btn-lg' href='#'>Hacer una Oferta</a>
  
  </div>
</div>

</div>
            






<div className='col-md-4 l-proyectos'>

  <div class="divcard">
	<div>
  <img src={Img1} alt="1" className='img'/>
	</div>
 <div class="cardinfo">
  <div class="div-2">
    <div class="div-3">Apartamento en Trinidad Green Plaza</div>
    <div class="div-4">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/24ccf17c-6f89-4628-adf0-29a22c903b98?apiKey=859fcd62e1a949e59d3a0240a7b39fbc&"
        class="img-2"
      />
      <div class="div-5">Av. Stma. Trinidad, 101 - Bo. Trinidad, Asunción</div>
    </div>
    <hr />
    <div className='div-5-1'>
    <strong>Ofrecido Por:</strong> Juan Gimenez
    </div>
    <div class="participacion">
    <span>6%</span>
    <div>Participación</div>
    </div>
    
  </div>
    <a className='btn btn-primary btn-lg' href='#'>Hacer una Oferta</a>
  
  </div>
</div>

</div>
            









<div className='col-md-4 l-proyectos'>

  <div class="divcard">
	<div>
  <img src={Img3} alt="1" className='img'/>
	</div>
 <div class="cardinfo">
  <div class="div-2">
    <div class="div-3">Apartamento en Molas Luxury Park</div>
    <div class="div-4">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/24ccf17c-6f89-4628-adf0-29a22c903b98?apiKey=859fcd62e1a949e59d3a0240a7b39fbc&"
        class="img-2"
      />
      <div class="div-5">Av. Molas Lopez, 265 - Bo. Trinidad, Asunción</div>
    </div>
    <hr />
    <div className='div-5-1'>
      <strong>Ofrecido Por:</strong> Juan Gimenez
    </div>
    <div class="participacion">
    <span>8%</span>
    <div>Participación</div>
    </div>

  </div>
    <a className='btn btn-primary btn-lg' href='#'>Hacer una Oferta</a>
  
  </div>
</div>

</div>
            








    </div>





    </div>
    </>
  );
}

export default Marketplace
