import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes} from "react-router-dom"
import './App.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Login from "./pages/Login"
import PrivacyPolicy from './pages/PrivacyPolicy'
import TermsOfService from './pages/TermsOfService'
import AvailableProjects from './pages/AvailableProjects'
import MyInvestments from './pages/MyInvestments'
import AvailableProjectsDetails from './pages/AvailableProjectsDetails'
import AvailableProjectsDetails0 from './pages/AvailableProjectsDetails0'
import MyInvestmentsDetails from './pages/MyInvestmentsDetails'
import MyInvestmentsDetails0 from './pages/MyInvestmentsDetails0'
import Dashboard from './pages/Dashboard'
import Marketplace from './pages/Marketplace'
import Rentals from './pages/Rentals'
import Sales from './pages/Sales'

function App() {

  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <div>
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route path="/terms-of-service" element={<TermsOfService />}></Route>
          <Route path="/dashboard" element={<Dashboard />}></Route>
          <Route path="/available-projects" element={<AvailableProjects />}></Route>
          <Route path="/my-investments" element={<MyInvestments />}></Route>
          <Route path="/available-projects-details" element={<AvailableProjectsDetails />}></Route>
          <Route path="/available-projects-details0" element={<AvailableProjectsDetails0 />}></Route>
          <Route path="/my-investments-details" element={<MyInvestmentsDetails />}></Route>
          <Route path="/my-investments-details0" element={<MyInvestmentsDetails0 />}></Route>
          <Route path="/marketplace" element={<Marketplace />}></Route>
          <Route path="/rentals" element={<Rentals />}></Route>
          <Route path="/sales" element={<Sales />}></Route>
        </Routes>
    </BrowserRouter>
    </div>
  )
}

export default App
