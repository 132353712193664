import React, { useEffect, useState } from 'react'
import Nav from "./components/Nav"
import { Link } from "react-router-dom"
import p from "./images/p.jpg"
import user from "./images/user.png"
import { 
  AreaChart,
  Area,
  BarChart, 
  Bar, 
  Cell, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend, 
  ResponsiveContainer,
  LineChart,
  Line,
  ComposedChart
} from "recharts"



const datarendi = [
  {
    name: '2021',
    AcumCartera: 4000,
    Rendimiento: 4000,
    amt: 2400,
  },
  {
    name: '2022',
    AcumCartera: 5000,
    Rendimiento: 7000,
    amt: 2210,
  },
  {
    name: '2023',
    AcumCartera: 6000,
    Rendimiento: 8000,
    amt: 2290,
  },

]


  const data = [
    {
      name: "(P1)",
      "Inversion a la fecha": 50000,
      "Ingresos": 2500,
      "Ingresos Previstos": 50000,
      "Ingresos Proyectados": 52500
    },
    {
      name: "(P2)",
      "Inversion a la fecha": 40000,
      "Ingresos": 1500,
      "Ingresos Previstos": 40000,
      "Ingresos Proyectados": 41500
    },
    {
      name: "(P3)",
      "Inversion a la fecha": 12500,
      "Ingresos": 800,
      "Ingresos Previstos": 12500,
      "Ingresos Proyectados": 17500
    },
    {
      name: "(P4)",
      "Inversion a la fecha": 22500,
      "Ingresos": 2000,
      "Ingresos Previstos": 22500,
      "Ingresos Proyectados": 27500
    },
    {
      name: "(P5)",
      "Inversion a la fecha": 8250,
      "Ingresos": 2000,
      "Ingresos Previstos": 8250,
      "Ingresos Proyectados": 8750
    },
    {
      name: "(P6)",
      "Inversion a la fecha": 30000,
      "Ingresos": 1500,
      "Ingresos Previstos": 30000,
      "Ingresos Proyectados": 32500
    },
  ]



  const data2 = [
    {
      name: "Ene",
      "Inversiones": 12775,
      "Cobros": 0,
      "Cashflow": -12775,
      "Cashflow acum": -12775
    },
    {
      name: "Feb",
      "Inversiones": 9000,
      "Cobros": 0,
      "Cashflow": -12775,
      "Cashflow acum": -12775
    },
    {
      name: "Mar",
      "Inversiones": 12775,
      "Cobros": 1000,
      "Cashflow": -12775,
      "Cashflow acum": -12775
    },
    {
      name: "Abr",
      "Inversiones": 8000,
      "Cobros": 0,
      "Cashflow": 2000,
      "Cashflow acum": -12775
    },
    {
      name: "May",
      "Inversiones": 10000,
      "Cobros": 1000,
      "Cashflow": -12775,
      "Cashflow acum": -12775
    },
    {
      name: "Jun",
      "Inversiones": 12775,
      "Cobros": 3000,
      "Cashflow": 3000,
      "Cashflow acum": -12775
    },
    {
      name: "Jul",
      "Inversiones": 12775,
      "Cobros": 0,
      "Cashflow": 500,
      "Cashflow acum": -12775
    },
    {
      name: "Ago",
      "Inversiones": 6000,
      "Cobros": 4000,
      "Cashflow": -12775,
      "Cashflow acum": -12775
    },
    {
      name: "Sep",
      "Inversiones": 20000,
      "Cobros": 1000,
      "Cashflow": -12775,
      "Cashflow acum": -12775
    },
    {
      name: "Oct",
      "Inversiones": 12775,
      "Cobros": 1000,
      "Cashflow": -12775,
      "Cashflow acum": -12775
    },
    {
      name: "Nov",
      "Inversiones": 10000,
      "Cobros": 0,
      "Cashflow": -12775,
      "Cashflow acum": -12775
    },
    {
      name: "Dic",
      "Inversiones": 6000,
      "Cobros": 1000,
      "Cashflow": -12775,
      "Cashflow acum": -12775
    },

  ]













  const data222 = [
    {
      name: "Ene",
      "Inversiones": 12775,
      "Cobros": 0,
      "Cashflow": -12775,
      "Cashflow acum": -12775
    },
    {
      name: "Feb",
      "Inversiones": 7000,
      "Cobros": 0,
      "Cashflow": -12775,
      "Cashflow acum": -12775
    },
    {
      name: "Mar",
      "Inversiones": 10775,
      "Cobros": 1000,
      "Cashflow": -12775,
      "Cashflow acum": -12775
    },
    {
      name: "Abr",
      "Inversiones": 6000,
      "Cobros": 0,
      "Cashflow": 2000,
      "Cashflow acum": -12775
    },
    {
      name: "May",
      "Inversiones": 7000,
      "Cobros": 2000,
      "Cashflow": -12775,
      "Cashflow acum": -12775
    },
    {
      name: "Jun",
      "Inversiones": 10775,
      "Cobros": 1000,
      "Cashflow": 3000,
      "Cashflow acum": -12775
    },
    {
      name: "Jul",
      "Inversiones": 8775,
      "Cobros": 0,
      "Cashflow": 500,
      "Cashflow acum": -12775
    },
    {
      name: "Ago",
      "Inversiones": 10000,
      "Cobros": 1000,
      "Cashflow": -12775,
      "Cashflow acum": -12775
    },
    {
      name: "Sep",
      "Inversiones": 10000,
      "Cobros": 3000,
      "Cashflow": -12775,
      "Cashflow acum": -12775
    },
    {
      name: "Oct",
      "Inversiones": 18775,
      "Cobros": 6000,
      "Cashflow": -12775,
      "Cashflow acum": -12775
    },
    {
      name: "Nov",
      "Inversiones": 14000,
      "Cobros": 0,
      "Cashflow": -12775,
      "Cashflow acum": -12775
    },
    {
      name: "Dic",
      "Inversiones": 16000,
      "Cobros": 1000,
      "Cashflow": -12775,
      "Cashflow acum": -12775
    },

  ]












  const data3 = [
    {
      name: "Ene",
      "Inversiones": 12775,
      "Cobros": 0,
    },
    {
      name: "Feb",
      "Inversiones": 9000,
      "Cobros": 0,
    },
    {
      name: "Mar",
      "Inversiones": 12775,
      "Cobros": 1000,
    },
    {
      name: "Abr",
      "Inversiones": 8000,
      "Cobros": 0,
    },
    {
      name: "May",
      "Inversiones": 10000,
      "Cobros": 1000,
    },
    {
      name: "Jun",
      "Inversiones": 12775,
      "Cobros": 3000,
    },
    {
      name: "Jul",
      "Inversiones": 12775,
      "Cobros": 0,
    },
    {
      name: "Ago",
      "Inversiones": 6000,
      "Cobros": 4000,
    },
    {
      name: "Sep",
      "Inversiones": 20000,
      "Cobros": 1000,
    },
    {
      name: "Oct",
      "Inversiones": 12775,
      "Cobros": 1000,
    },
    {
      name: "Nov",
      "Inversiones": 10000,
      "Cobros": 0,
    },
    {
      name: "Dic",
      "Inversiones": 6000,
      "Cobros": 1000,
    },

  ]



















  const data4 = [
    {
      name: "Ene",
      "2022": 10000,
      "2023": 10000
    },
    {
      name: "Feb",
      "2022": 18000,
      "2023": 20000
    },
    {
      name: "Mar",
      "2022": 25000,
      "2023": 30000
    },
    {
      name: "Abr",
      "2022": 30000,
      "2023": 38000
    },
    {
      name: "May",
      "2022": 40000,
      "2023": 50000
    },
    {
      name: "Jun",
      "2022": 55000,
      "2023": 56000
    },
    {
      name: "Jul",
      "2022": 40000,
      "2023": 66000
    },
    {
      name: "Ago",
      "2022": 70000,
      "2023": 76000
    },
    {
      name: "Sep",
      "2022": 70000,
      "2023": 80000
    },
    {
      name: "Oct",
      "2022": 65000,
      "2023": 90000
    },
    {
      name: "Nov",
      "2022": 85000,
      "2023": 90000
    },
    {
      name: "Dic",
      "2022": 100000,
      "2023": 90000
    },

  ]






  const data422 = [
    {
      name: "Ene",
      "2022": 10000,
      "2021": 7000
    },
    {
      name: "Feb",
      "2022": 18000,
      "2021": 16000
    },
    {
      name: "Mar",
      "2022": 25000,
      "2021": 21000
    },
    {
      name: "Abr",
      "2022": 30000,
      "2021": 25000
    },
    {
      name: "May",
      "2022": 40000,
      "2021": 30000
    },
    {
      name: "Jun",
      "2022": 55000,
      "2021": 40000
    },
    {
      name: "Jul",
      "2022": 40000,
      "2021": 30000
    },
    {
      name: "Ago",
      "2022": 70000,
      "2021": 60000
    },
    {
      name: "Sep",
      "2022": 70000,
      "2021": 60000
    },
    {
      name: "Oct",
      "2022": 65000,
      "2021": 55000
    },
    {
      name: "Nov",
      "2022": 85000,
      "2021": 60000
    },
    {
      name: "Dic",
      "2022": 100000,
      "2021": 70000
    },

  ]



const Dashboard = () => {

  const [opcionRendimiento, setOpcionRendimiento] = useState('rultimos3')

  const cambiarRendimiento = (e) => {
    setOpcionRendimiento(e.target.value)

    if(e.target.value == "r2023"){
      iniciarConteo(20)
    }else if(e.target.value == "r2022"){
      iniciarConteo(18)
    }else{
      iniciarConteo(15)
    }
    
  }


  const [opcionFlujo, setOpcionFlujo] = useState('f2023')

  const cambiarFlujo = (e) => {
    setOpcionFlujo(e.target.value)
  }


  const [opcionInv, setOpcionInv] = useState('i2023')

  const cambiarInv = (e) => {
    setOpcionInv(e.target.value)
  }




    const [porcentaje, setPorcentaje] = useState(0)
    const [porcentajeFinal, setPorcentajeFinal] = useState(20)

    const iniciarConteo = (p) => {
      setPorcentaje(0)
      setPorcentajeFinal(p)
    }
  
    useEffect(() => {
      const interval = setInterval(() => {
        if (porcentaje < porcentajeFinal) {
          setPorcentaje((prevPorcentaje) => prevPorcentaje + 1)
        } else {
          clearInterval(interval)
        }
      }, 50)
  
      return () => clearInterval(interval)
    }, [porcentaje])




    return (
      <>
      <Nav activeLink={"Inicio"}/>
      <br /><br /><br /><br />
    <div className="container">
      <div className="row enc">
      <div className="col-md-12">
        <h4>Dashboard</h4>
      </div>
      </div>
      <div className="row">
      <div className='col-md-3'>
        <div className='colmd3'>
        <div className='perfil'>
          <h4>Mi Perfil</h4>
          <img className="img-fluid" src={user} alt="user" />
          <h5>Carlos González</h5>
          <div className='pinfo'>
            <div className='row'>
              <div className='col'>
                <h6>Registrado el</h6>
              <span>28/08/2023 16:45</span>
              </div>
              <div className='col separa'>
                <h6>Último Acceso</h6>
                <span>19/12/2023 10:26</span>
              </div>
            </div>
          
          </div>
        </div>
        </div>
        <br />
        <div className='colmd3 rendimiento'>
         <h4>Rendimiento</h4>
         <select
         className="form-select" 
         aria-label="Default select rendimiento"
         value={opcionRendimiento}
         onChange={cambiarRendimiento}
         >
            <option value="rultimos3" defaultValue>Últimos 3 Años</option>
            <option value="r2023">Año 2023</option>
            <option value="r2022">Año 2022</option>
            <option value="r2021">Año 2021</option>
        </select>

       <div id="rultimos3" style={{ display: opcionRendimiento === 'rultimos3' ? 'block' : 'none' }} className="crendimiento">
       <div className="rultimos">
       <ResponsiveContainer>
        <ComposedChart
          width={500}
          height={400}
          data={datarendi}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          barSize={30}
        >
        
          <XAxis dataKey="name" scale="band" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="Rendimiento" fill="#112c4c" background={{ fill: '#eee' }} />
         <Line type="monotone" dataKey="AcumCartera" stroke="#00ad45" />
        </ComposedChart>
      </ResponsiveContainer>
      </div>
     
        <div className='inversioninfo'>
          <span><i class="fas fa-calendar-alt"></i> Inversión a la Fecha</span>
          <h2>U$D 127.750</h2>
          <hr></hr>
          <span><i class="fas fa-hand-holding-usd"></i> Ingresos</span>
          <h2>U$D 10.500</h2>
        </div>
      </div>


      <div id="r2023" style={{ display: opcionRendimiento === 'r2023' ? 'block' : 'none' }} className="crendimiento">
       <br /> <br />
        <div className="prendimiento">
          {porcentaje}%
        </div>
        <br /> <br />
     
        <div className='inversioninfo'>
          <span><i class="fas fa-calendar-alt"></i> Inversión a la Fecha</span>
          <h2>U$D 127.750</h2>
          <hr></hr>
          <span><i class="fas fa-hand-holding-usd"></i> Ingresos</span>
          <h2>U$D 4.500</h2>
        </div>
        <br />
      </div>





      <div id="r2022" style={{ display: opcionRendimiento === 'r2022' ? 'block' : 'none' }} className="crendimiento">
       <br /> <br />
        <div className="prendimiento">
          {porcentaje}%
        </div>
        <br /> <br />
     
        <div className='inversioninfo'>
          <span><i class="fas fa-calendar-alt"></i> Inversión a la Fecha</span>
          <h2>U$D 127.750</h2>
          <hr></hr>
          <span><i class="fas fa-hand-holding-usd"></i> Ingresos</span>
          <h2>U$D 2.500</h2>
        </div>
        <br />
      </div>


      <div id="r2021" style={{ display: opcionRendimiento === 'r2021' ? 'block' : 'none' }} className="crendimiento">
       <br /> <br />
        <div className="prendimiento">
          {porcentaje}%
        </div>
        <br /> <br />
     
        <div className='inversioninfo'>
          <span><i class="fas fa-calendar-alt"></i> Inversión a la Fecha</span>
          <h2>U$D 127.750</h2>
          <hr></hr>
          <span><i class="fas fa-hand-holding-usd"></i> Ingresos</span>
          <h2>U$D 3.000</h2>
        </div>
        <br />
      </div>




        <br />
        </div>
        <br />
      </div>
      
        <div className="col-md-6">
            <div className="table1">
            <h4>Proyectos en donde estoy Invirtiendo</h4>
        <table className="table">
  <thead>
    <tr>
      <th scope="col"></th>
      <th scope="col">Propiedades <br />Invertidas</th>
      
      <th scope="col">Inversión <br />a la Fecha</th>
      <th scope="col">Ingresos <br />(U$D)</th>
      <th scope="col">Partici- <br />pación</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><i className="fas fa-circle cverde"></i></td>
      <th scope="row"><img className="img-fluid min49" src={p} alt="p" /> <Link to="/my-investments-details">Proyecto1 (P1)</Link></th>
      <td>50.000</td>
      <td>2.500</td>
      <td>2%</td>
    </tr>
    <tr>
      <td><i className="fas fa-circle cverde"></i></td>
      <th scope="row"><img className="img-fluid min49" src={p} alt="p" /> <Link to="/my-investments-details">Proyecto2 (P2)</Link></th>
      <td>40.000</td>
      <td>1.500</td>
      <td>3%</td>
    </tr>
    <tr>
      <td><i className="fas fa-circle cverde"></i></td>
      <th scope="row"><img className="img-fluid min49" src={p} alt="p" /> <Link to="/my-investments-details">Proyecto3 (P3)</Link></th>
      <td>1.250</td>
      <td>500</td>
      <td>4%</td>
    </tr>
    <tr>
      <td><i className="fas fa-circle cverde"></i></td>
      <th scope="row"><img className="img-fluid min49" src={p} alt="p" /> <Link to="/my-investments-details">Proyecto4 (P4)</Link></th>
      <td>2.250</td>
      <td>1.000</td>
      <td>10%</td>
    </tr>
    <tr>
      <td><i className="fas fa-circle cverde"></i></td>
      <th scope="row"><img className="img-fluid min49" src={p} alt="p" /> <Link to="/my-investments-details">Proyecto5 (P5)</Link></th>
      <td>4.250</td>
      <td>2.000</td>
      <td>6%</td>
    </tr>
    <tr>
      <td><i className="fas fa-circle cverde"></i></td>
      <th scope="row"><img className="img-fluid min49" src={p} alt="p" /> <Link to="/my-investments-details">Proyecto6 (P6)</Link></th>
      <td>30.000</td>
      <td>1.500</td>
      <td>4%</td>
    </tr>
    <tr>
      <td></td>
      <th scope="row"></th>
      <td><strong>127.750</strong></td>
      <td><strong>10.500</strong></td>
      <td></td>
    </tr>
  </tbody>
</table>
</div>

<div>
        <br />
        <div className="charts">
            <spam className="tit-flujo">Flujo de Caja</spam><br />
            <spam className="usd-flujo">U$D 10.500</spam>
            <spam className="porc-flujo">+10%</spam>
            
  <select 
  className="form-select flujo" 
  aria-label="Default select example"
  value={opcionFlujo}
  onChange={cambiarFlujo}
  >
  <option value="f2023" defaultValue>Periodo 2023</option>
  <option value="f2022">Periodo 2022</option>
  <option value="f2021">Periodo 2021</option>
</select>
<br /><br />
<div id="f2023" className="wflujo" style={{ display: opcionFlujo === 'f2023' ? 'block' : 'none' }}>
      <ResponsiveContainer>
        <AreaChart
          width={500}
          height={300}
          data={data4}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          {/*<Area type="monotone" dataKey="Cashflow acum" stackId="1" stroke="#a7e5fe" fill="#a7e5fe" />
          <Area type="monotone" dataKey="Cashflow" stackId="1" stroke="#02AAEC" fill="#02AAEC" />*/}
          
          <Area type="monotone" dataKey="2023" stroke="#02AAEC" fill="#02AAEC" />
          <Area type="monotone" dataKey="2022" stroke="#a7e5fe" fill="#a7e5fe" />
        </AreaChart>
      </ResponsiveContainer>
      </div>





      <div id="f2022" className="wflujo" style={{ display: opcionFlujo === 'f2022' ? 'block' : 'none' }}>
      <ResponsiveContainer>
        <AreaChart
          width={500}
          height={300}
          data={data422}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          {/*<Area type="monotone" dataKey="Cashflow acum" stackId="1" stroke="#a7e5fe" fill="#a7e5fe" />
          <Area type="monotone" dataKey="Cashflow" stackId="1" stroke="#02AAEC" fill="#02AAEC" />*/}
          
          <Area type="monotone" dataKey="2022" stroke="#02AAEC" fill="#02AAEC" />
          <Area type="monotone" dataKey="2021" stroke="#a7e5fe" fill="#a7e5fe" />
        </AreaChart>
      </ResponsiveContainer>
      </div>






      <div id="f2021" className="wflujo" style={{ display: opcionFlujo === 'f2021' ? 'block' : 'none' }}>
      <ResponsiveContainer>
        <AreaChart
          width={500}
          height={300}
          data={data422}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          {/*<Area type="monotone" dataKey="Cashflow acum" stackId="1" stroke="#a7e5fe" fill="#a7e5fe" />
          <Area type="monotone" dataKey="Cashflow" stackId="1" stroke="#02AAEC" fill="#02AAEC" />*/}
          
          <Area type="monotone" dataKey="2021" stroke="#02AAEC" fill="#02AAEC" />
          {/*<Area type="monotone" dataKey="2021" stroke="#a7e5fe" fill="#a7e5fe" />*/}
        </AreaChart>
      </ResponsiveContainer>
      </div>




    </div>
    <br />
    
        </div>
        
        </div>

  <div className='col-md-3'>
  <div className='colmd3 mkplace'>
    <h5><Link to="/marketplace">Marketplace </Link></h5>
    <div class="timeline">
        <div class="timeline-item">
          <div class="row">
            <div class="col">
              <a href="#">Hacer una Oferta</a>
            </div>
            <div class="col">
              <span className="fechamp">19/12/2023 10:26</span>
              <br />
              <span className="disponiblemp">Disponible</span>
            </div>
          </div>
          <br />
          <div className="row">
            
            <div className="col contmp">
              <div className="row">
                <div className="col-4"><img className="img-fluid" src={p} alt="p" /></div>
                <div className="col-8">
                  <div className="tituloitemmp">Apart. Villa Morra</div>
                  <div className="demp">De Juan Gimenez</div>
                </div>
              </div>

            <div className="tirvan">TIR:<strong>50%</strong> VAN:<strong>50.000 U$D</strong>
            <br />ROC:<strong>50%</strong> ROE:<strong>50%</strong> ROS:<strong>50%</strong></div>
              <hr/>
              <div className="parti">5% PARTICIPACIÓN</div>
            </div>
          </div>
        </div>
    
    



        <div class="timeline-item">
          <div class="row">
            <div class="col">
              <a href="#">Hacer una Oferta</a>
            </div>
            <div class="col">
              <span className="fechamp">19/12/2023 10:26</span>
              <br />
              <span className="disponiblemp">Disponible</span>
            </div>
          </div>
          <br />
          <div className="row">
            
            <div className="col contmp">
              <div className="row">
                <div className="col-4"><img className="img-fluid" src={p} alt="p" /></div>
                <div className="col-8">
                  <div className="tituloitemmp">Apart. Villa Morra</div>
                  <div className="demp">De Juan Gimenez</div>
                </div>
              </div>

            <div className="tirvan">TIR:<strong>50%</strong> VAN:<strong>50.000 U$D</strong>
            <br />ROC:<strong>50%</strong> ROE:<strong>50%</strong> ROS:<strong>50%</strong></div>
              <hr/>
              <div className="parti">5% PARTICIPACIÓN</div>
            </div>
          </div>
        </div>





        <div class="timeline-item">
          <div class="row">
            <div class="col">
              <a href="#">Hacer una Oferta</a>
            </div>
            <div class="col">
              <span className="fechamp">19/12/2023 10:26</span>
              <br />
              <span className="disponiblemp">Disponible</span>
            </div>
          </div>
          <br />
          <div className="row">
            
            <div className="col contmp">
              <div className="row">
                <div className="col-4"><img className="img-fluid" src={p} alt="p" /></div>
                <div className="col-8">
                  <div className="tituloitemmp">Apart. Villa Morra</div>
                  <div className="demp">De Juan Gimenez</div>
                </div>
              </div>

            <div className="tirvan">TIR:<strong>50%</strong> VAN:<strong>50.000 U$D</strong>
            <br />ROC:<strong>50%</strong> ROE:<strong>50%</strong> ROS:<strong>50%</strong></div>
              <hr/>
              <div className="parti">5% PARTICIPACIÓN</div>
            </div>
          </div>
        </div>




        <div class="timeline-item">
          <div class="row">
            <div class="col">
              <a href="#">Hacer una Oferta</a>
            </div>
            <div class="col">
              <span className="fechamp">19/12/2023 10:26</span>
              <br />
              <span className="disponiblemp">Disponible</span>
            </div>
          </div>
          <br />
          <div className="row">
            
            <div className="col contmp">
              <div className="row">
                <div className="col-4"><img className="img-fluid" src={p} alt="p" /></div>
                <div className="col-8">
                  <div className="tituloitemmp">Apart. Villa Morra</div>
                  <div className="demp">De Juan Gimenez</div>
                </div>
              </div>

            <div className="tirvan">TIR:<strong>50%</strong> VAN:<strong>50.000 U$D</strong>
            <br />ROC:<strong>50%</strong> ROE:<strong>50%</strong> ROS:<strong>50%</strong></div>
              <hr/>
              <div className="parti">5% PARTICIPACIÓN</div>
            </div>
          </div>
        </div>

    
    
    </div>
    <a href="#" className="btn btn-secondary">PUBLICAR EN MARKETPLACE</a>
    <br /><br />
    </div>
  </div>




        
      </div>
      




      <div className='row'>
        
      <div className="col-md-12">
        <br />
        <div className="charts-1 text-center">
            <spam className="title-gr">Inversión Mensual U$D (Todos los proyectos)</spam> 
    <select 
      className="form-select periodo" 
      aria-label="Default select inv"
      value={opcionInv}
      onChange={cambiarInv}
    >
      <option value="i2023" defaultValue>Periodo 2023</option>
      <option value="i2022">Periodo 2022</option>
      <option value="i2021">Periodo 2021</option>
    </select>
    <div id="i2023" style={{ display: opcionInv === 'i2023' ? 'block' : 'none' }} className="wflujo">
    <ResponsiveContainer>
    <BarChart
    
      width={1200}
      height={300}
      data={data2}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5
      }}
      barSize={15}
    >
      <XAxis dataKey="name" axisLine={false}/>
      <YAxis axisLine={false} />
      <Tooltip />
      <Legend />
      <Bar dataKey="Inversiones" stackId="a" fill="#ff6767" />
      <Bar dataKey="Cobros" stackId="a" fill="#84d053" />

    </BarChart>
    </ResponsiveContainer>
    </div>







    <div id="i2022" style={{ display: opcionInv === 'i2022' ? 'block' : 'none' }} className="wflujo">
    <ResponsiveContainer>
    <BarChart
    
      width={1200}
      height={300}
      data={data222}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5
      }}
      barSize={15}
    >
      <XAxis dataKey="name" axisLine={false}/>
      <YAxis axisLine={false} />
      <Tooltip />
      <Legend />
      <Bar dataKey="Inversiones" stackId="a" fill="#ff6767" />
      <Bar dataKey="Cobros" stackId="a" fill="#84d053" />

    </BarChart>
    </ResponsiveContainer>
    </div>









    <div id="i2021" style={{ display: opcionInv === 'i2021' ? 'block' : 'none' }} className="wflujo">
    <ResponsiveContainer>
    <BarChart
    
      width={1200}
      height={300}
      data={data2}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5
      }}
      barSize={15}
    >
      <XAxis dataKey="name" axisLine={false}/>
      <YAxis axisLine={false} />
      <Tooltip />
      <Legend />
      <Bar dataKey="Inversiones" stackId="a" fill="#ff6767" />
      <Bar dataKey="Cobros" stackId="a" fill="#84d053" />

    </BarChart>
    </ResponsiveContainer>
    </div>







    </div>
    <br /><br />
        </div>
      </div>








    </div>
      </>
    )
}

export default Dashboard