import React from "react"
import Nav from "./components/Nav"
import { Link } from "react-router-dom"
import Img1 from "./images/img1.jpg"
import Img2 from "./images/img2.jpg"
import Stats from "./images/stats.svg"
import AvailableProjectsDetails from "./AvailableProjectsDetails"

const AvailableProjects = () => {
  return (
    <>
    <Nav activeLink={"AvailableProjects"}/>
    <br /><br /><br /><br />
    <div className="container">
      <div className="row enc">
      <div className="col-md-4">
        <span>Proyectos Disponibles (6)</span>
      </div>
      <div className="col-md-4">
        
      </div>
      <div className="col-md-4">
        <span><strong>Dashboard</strong> &gt; Proyectos Disponibles</span>
      </div>
      </div>



    <div className="row">
  


    <div className="col-md-6 card-e">
    <Link to="/available-projects-details"> 
      <div class="row g-0 card1 overflow-hidden flex-md-row mb-4 h-md-250 position-relative">
        <div class="col p-4 d-flex flex-column position-static ainfo">
          <strong class="d-inline-block mb-1 text-primary-emphasis">Barrio Jara</strong>
          <h3 class="mb-1 card-title">Proyecto Apartamentos 1</h3>
          <div class="mb-1 text-body-secondary">12 Nov 2023</div>
          <p class="card-text">
            <strong>Monto mínimo:</strong> 20.000 U$D<br />
            <strong>Formato:</strong> Venta/Alquiler<br />
            <img className="astats" src={Stats} alt="stats"/> 
          </p>
           
        </div>
        <div class="col-auto d-none d-lg-block">
          <img className="img1" src={Img1} alt="img1"/>
        </div>
      </div>
      </Link>
      </div>



      <div className="col-md-6 card-e">
    <Link to="/available-projects-details0"> 
      <div class="row g-0 card1 overflow-hidden flex-md-row mb-4 h-md-250 position-relative">
        <div class="col p-4 d-flex flex-column position-static ainfo">
          <strong class="d-inline-block mb-1 text-primary-emphasis">Molas lópez</strong>
          <h3 class="mb-1 card-title">Proyecto Apartamentos 2</h3>
          <div class="mb-1 text-body-secondary">6 Ago 2023</div>
          <p class="card-text">
            <strong>Monto mínimo:</strong> 30.000 U$D<br />
            <strong>Formato:</strong> Venta/Alquiler<br />
            <img className="astats" src={Stats} alt="stats"/> 
          </p>
          
        </div>
        <div class="col-auto d-none d-lg-block">
          <img className="img1" src={Img2} alt="img2"/>
        </div>
      </div>
      </Link>
      </div>


      



      <div className="col-md-6 card-e">
    <Link to="/available-projects-details0">
      <div class="row g-0 card1 overflow-hidden flex-md-row mb-4 h-md-250 position-relative">
        <div class="col p-4 d-flex flex-column position-static ainfo">
          <strong class="d-inline-block mb-1 text-primary-emphasis">Molas lópez</strong>
          <h3 class="mb-1 card-title">Proyecto Apartamentos 3</h3>
          <div class="mb-1 text-body-secondary">6 Ago 2023</div>
          <p class="card-text">
            <strong>Monto mínimo:</strong> 30.000 U$D<br />
            <strong>Formato:</strong> Venta/Alquiler<br />
            <img className="astats" src={Stats} alt="stats"/> 
          </p>
          
        </div>
        <div class="col-auto d-none d-lg-block">
          <img className="img1" src={Img2} alt="img2"/>
        </div>
      </div>
      </Link>
      </div>


      <div className="col-md-6 card-e">
    <Link to="/available-projects-details">
      <div class="row g-0 card1 overflow-hidden flex-md-row mb-4 h-md-250 position-relative">
        <div class="col p-4 d-flex flex-column position-static ainfo">
          <strong class="d-inline-block mb-1 text-primary-emphasis">Barrio Jara</strong>
          <h3 class="mb-1 card-title">Proyecto Apartamentos 4</h3>
          <div class="mb-1 text-body-secondary">12 Nov 2023</div>
          <p class="card-text">
            <strong>Monto mínimo:</strong> 20.000 U$D<br />
            <strong>Formato:</strong> Venta/Alquiler<br />
            <img className="astats" src={Stats} alt="stats"/> 
          </p>
          
        </div>
        <div class="col-auto d-none d-lg-block">
          <img className="img1" src={Img1} alt="img1"/>
        </div>
      </div>
      </Link>
      </div>






      <div className="col-md-6 card-e">
    <Link to="/available-projects-details">
      <div class="row g-0 card1 overflow-hidden flex-md-row mb-4 h-md-250 position-relative">
        <div class="col p-4 d-flex flex-column position-static ainfo">
          <strong class="d-inline-block mb-1 text-primary-emphasis">Barrio Jara</strong>
          <h3 class="mb-1 card-title">Proyecto Apartamentos 5</h3>
          <div class="mb-1 text-body-secondary">12 Nov 2023</div>
          <p class="card-text">
            <strong>Monto mínimo:</strong> 20.000 U$D<br />
            <strong>Formato:</strong> Venta/Alquiler<br />
            <img className="astats" src={Stats} alt="stats"/> 
          </p>
          
        </div>
        <div class="col-auto d-none d-lg-block">
          <img className="img1" src={Img1} alt="img1"/>
        </div>
      </div>
      </Link>
      </div>






      <div className="col-md-6 card-e">
    <Link to="/available-projects-details0">
      <div class="row g-0 card1 overflow-hidden flex-md-row mb-4 h-md-250 position-relative">
        <div class="col p-4 d-flex flex-column position-static ainfo">
          <strong class="d-inline-block mb-1 text-primary-emphasis">Molas lópez</strong>
          <h3 class="mb-1 card-title">Proyecto Apartamentos 6</h3>
          <div class="mb-1 text-body-secondary">6 Ago 2023</div>
          <p class="card-text">
            <strong>Monto mínimo:</strong> 30.000 U$D<br />
            <strong>Formato:</strong> Venta/Alquiler<br />
            <img className="astats" src={Stats} alt="stats"/> 
          </p>
          
        </div>
        <div class="col-auto d-none d-lg-block">
          <img className="img1" src={Img2} alt="img2"/>
        </div>
      </div>
      </Link>
      </div>



    </div>





    </div>
    </>
  );
}

export default AvailableProjects
