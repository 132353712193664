import React from "react"
import Nav from "./components/Nav"
import { Link } from "react-router-dom"
import Img1 from "./images/img3-2.jpg"

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts'


  const data = [
    {
      name: "Ene",
      "Ingresos": 3000,
      "Egresos": -3000
    },
    {
      name: "Feb",
      "Ingresos": 3000,
      "Egresos": -3000
    },
    {
      name: "Mar",
      "Ingresos": 1000,
      "Egresos": 0
    },
    {
      name: "Abr",
      "Ingresos": 2000,
      "Egresos": -1000
    },
    {
      name: "May",
      "Ingresos": 5000,
      "Egresos": 0
    },
    {
      name: "Jun",
      "Ingresos": 3000,
      "Egresos": 0
    },
    {
      name: "Jul",
      "Ingresos": 3000,
      "Egresos": -3000
    },
    {
      name: "Ago",
      "Ingresos": 5000,
      "Egresos": 0
    },
    {
      name: "Sep",
      "Ingresos": 3000,
      "Egresos": -3000
    },
    {
      name: "Oct",
      "Ingresos": 6000,
      "Egresos": 0
    },
    {
      name: "Nov",
      "Ingresos": 7000,
      "Egresos": -3000
    },
    {
      name: "Dic",
      "Ingresos": 8000,
      "Egresos": 0
    },
  ]



const MyInvestmentsDetails = () => {
    return (
      <>
    <Nav activeLink={"MyInvestments"}/>
    <br /><br /><br /><br />
<div className="container">
      <div className="row enc">
      <div className="col-md-4">
        <span><strong>Proyecto Apartamentos 1</strong></span>
      </div>
      <div className="col-md-4">
        
      </div>
      <div className="col-md-4">
        <span><strong>Dashboard</strong> &gt; Mis Inversiones</span>
      </div>
      </div>


    <div className="row">
        <div className="col-md-6">
            <div className="borde-img">
            <img className="img-fluid" src={Img1} alt="img1" />
            </div>
            <div className="descripcionap">
              <p>Apartamento Ubicado en Villamorra, de un ambiente, el complejo residencial cuenta con una impresionante piscina rodeada de zonas verdes y espacios ajardinados.</p>
            </div>
        </div>
        <div className="col-md-6">

        <table class="table">
  <tbody>
  <tr>
      <td><strong>Tipo de desarrollo:</strong> Tipo 1</td>
      <td><strong>Formato Comercial:</strong> Venta/Alquiler</td>
    </tr>
    <tr>
      <th scope="row">Costo del Proyecto:</th>
      <td>5.000.000 U$D</td>
    </tr>
    <tr>
      <th scope="row">Venta del Proyecto: </th>
      <td>9.000.000 U$D</td>
    </tr>
    <tr>
      <td> <strong>TIR:</strong> 50%  </td>
      <td><strong>VAN:</strong> 50.000 U$D</td>
    </tr>
    <tr>
      <td><strong>ROC:</strong> 50%  </td>
      <td><strong>ROE:</strong> 50%</td>
    </tr>
    <tr>
      <td><strong>ROS:</strong> 50%</td>
      <td></td>
    </tr>
    <tr>
      <th scope="row"> Monto Invertido:</th>
      <td>50.000 U$D</td>
    </tr>
    <tr>
      <td><strong>Coe Costo:</strong> 15%</td>
      <td><strong> Coe Participación:</strong> 80%</td>
    </tr>
    <tr>
      <td><strong>Coe Dividendos:</strong> 10%</td>
      <td><strong>Utilidades:</strong> 20.000 U$D</td>
    </tr>
    <tr>
      <td><strong>Retorno Capital:</strong> 40%</td>
      <td><strong>Retorno anual: </strong>5%</td>
    </tr>
    <tr>
      <td>Fechas:</td>
      <td>Plazo:</td>
    </tr>
  </tbody>
</table>

<Link to="/rentals" className="btn btn-primary">Ver detalles de Alquiler</Link>
&nbsp;&nbsp;&nbsp;
<Link to="/sales" className="btn btn-warning">Ver detalles de Venta</Link>
        </div>
    
    </div>
    <br /><br /> 
    <div className="row">
        
    <div className="col-md-12">
            <div className="borde-img">
            <div className="charts text-center">
            <h5>Flujo de ingresos/egresos del Proyecto 2023</h5>
            <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={1200}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 50,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <ReferenceLine x="May" stroke="red" label="Previsto >>" />
          <Line type="monotone" dataKey="Ingresos" stroke="#8884d8" />
          <Line type="monotone" dataKey="Egresos" stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer>
            </div>
            
        </div>
        <br />
        </div>
    </div>










    <div className="row">
        
        <div className="col-md-12 text-center">
        <h5>Avances del Proyecto</h5>
                <div className="borde-img">
                 
                <div className="table1">
        <table class="table">
  <thead>
    <tr>
      <th scope="col">Fecha</th>
      <th scope="col">link</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>21/10/2023</td>
      <td><a href="#">http://app.orioncapital/download/avence1.pdf</a></td>

    </tr>
    <tr>
    <td>13/09/2023</td>
    <td><a href="#">http://app.orioncapital/download/avence2.pdf</a></td>
    </tr>
  </tbody>
  </table>


          </div>
        </div>
        <br /> <br /> <br />
    </div>
    
</div>
</div>
      </>
    )
}
export default MyInvestmentsDetails