import React from "react"
import Nav from "./components/Nav"
import { Link } from "react-router-dom"
import Img3 from "./images/img3.jpg"
import Img4 from "./images/img4.jpg"
import Stats from "./images/stats.svg"
import Circle1 from "./images/c1.svg"
import Circle2 from "./images/c2.svg"

const MyInvestments = () => {
  return (
    <>
    <Nav activeLink={"MyInvestments"}/>
    <br /><br /><br /><br />
    <div className="container">
      <div className="row enc">
      <div className="col-md-4">
        <span>Mis Inversiones (4/6)</span>
      </div>
      <div className="col-md-4">
        
      </div>
      <div className="col-md-4">
        <span><strong>Dashboard</strong> &gt; Mis Inversiones</span>
      </div>
      </div>



    <div className="row">
  


    <div className="col-md-6 card-e">
    <Link to="/my-investments-details">
      <div class="row g-0 card1 overflow-hidden flex-md-row mb-4 h-md-250 position-relative">
        <div class="col p-4 d-flex flex-column position-static ainfo">
          <strong class="d-inline-block mb-1 text-primary-emphasis">Barrio Recoleta</strong>
          <h3 class="mb-1 card-title"><img className="circle" src={Circle1} alt="c1"/> Proyecto Apartamentos 1</h3>
          <div class="mb-1 text-body-secondary">23 Oct 2023</div>
          <p class="card-text">
            <strong>Monto Invertido:</strong> 50.000 U$D<br />
            <strong>Ingresos a la fecha:</strong> 2.500 U$D<br />
            <img className="astats" src={Stats} alt="stats"/> 
          </p>
          
        </div>
        <div class="col-auto d-none d-lg-block">
          <img className="img1" src={Img3} alt="img1"/>
        </div>
      </div>
      </Link>
      </div>


      <div className="col-md-6 card-e">
    <Link to="/my-investments-details0">
      <div class="row g-0 card1 overflow-hidden flex-md-row mb-4 h-md-250 position-relative">
        <div class="col p-4 d-flex flex-column position-static ainfo">
          <strong class="d-inline-block mb-1 text-primary-emphasis">Av. Stma. Trinidad</strong>
          <h3 class="mb-1 card-title"><img className="circle" src={Circle1} alt="c1"/> Proyecto Apartamentos 2</h3>
          <div class="mb-1 text-body-secondary">12 Nov 2023</div>
          <p class="card-text">
            <strong>Monto Invertido:</strong> 45.000 U$D<br />
            <strong>Ingresos a la fecha:</strong> 2.200 U$D<br />
            <img className="astats" src={Stats} alt="stats"/> 
          </p>
          
        </div>
        <div class="col-auto d-none d-lg-block">
          <img className="img1" src={Img4} alt="img4"/>
        </div>
      </div>
      </Link>
      </div>



      <div className="col-md-6 card-e">
    <Link to="/my-investments-details0">
      <div class="row g-0 card1 overflow-hidden flex-md-row mb-4 h-md-250 position-relative">
        <div class="col p-4 d-flex flex-column position-static ainfo">
          <strong class="d-inline-block mb-1 text-primary-emphasis">Av. Stma. Trinidad</strong>
          <h3 class="mb-1 card-title"><img className="circle" src={Circle1} alt="c1"/> Proyecto Apartamentos 3</h3>
          <div class="mb-1 text-body-secondary">12 Nov 2023</div>
          <p class="card-text">
            <strong>Monto Invertido:</strong> 45.000 U$D<br />
            <strong>Ingresos a la fecha:</strong> 2.200 U$D<br />
            <img className="astats" src={Stats} alt="stats"/> 
          </p>
          
        </div>
        <div class="col-auto d-none d-lg-block">
          <img className="img1" src={Img4} alt="img4"/>
        </div>
      </div>
      </Link>
      </div>




      <div className="col-md-6 card-e">
    <Link to="/my-investments-details">
      <div class="row g-0 card1 overflow-hidden flex-md-row mb-4 h-md-250 position-relative">
        <div class="col p-4 d-flex flex-column position-static ainfo">
          <strong class="d-inline-block mb-1 text-primary-emphasis">Barrio Recoleta</strong>
          <h3 class="mb-1 card-title"><img className="circle" src={Circle1} alt="c1"/> Proyecto Apartamentos 4</h3>
          <div class="mb-1 text-body-secondary">23 Oct 2023</div>
          <p class="card-text">
            <strong>Monto Invertido:</strong> 50.000 U$D<br />
            <strong>Ingresos a la fecha:</strong> 2.500 U$D<br />
            <img className="astats" src={Stats} alt="stats"/> 
          </p>
          
        </div>
        <div class="col-auto d-none d-lg-block">
          <img className="img1" src={Img3} alt="img1"/>
        </div>
      </div>
      </Link>
      </div>



      <div className="col-md-6 card-e">
    <Link to="/my-investments-details">
      <div class="row g-0 card1 overflow-hidden flex-md-row mb-4 h-md-250 position-relative">
        <div class="col p-4 d-flex flex-column position-static ainfo">
          <strong class="d-inline-block mb-1 text-primary-emphasis">Barrio Recoleta</strong>
          <h3 class="mb-1 card-title"><img className="circle" src={Circle2} alt="c2"/> Proyecto Apartamentos 5</h3>
          <div class="mb-1 text-body-secondary">23 Oct 2023</div>
          <p class="card-text">
            <strong>Monto Invertido:</strong> 50.000 U$D<br />
            <strong>Ingresos a la fecha:</strong> 2.500 U$D<br />
            <img className="astats" src={Stats} alt="stats"/> 
          </p>
          
        </div>
        <div class="col-auto d-none d-lg-block">
          <img className="img1" src={Img3} alt="img1"/>
        </div>
      </div>
      </Link>
      </div>












      <div className="col-md-6 card-e">
    <Link to="/my-investments-details0">
      <div class="row g-0 card1 overflow-hidden flex-md-row mb-4 h-md-250 position-relative">
        <div class="col p-4 d-flex flex-column position-static ainfo">
          <strong class="d-inline-block mb-1 text-primary-emphasis">Av. Stma. Trinidad</strong>
          <h3 class="mb-1 card-title"><img className="circle" src={Circle2} alt="c2"/> Proyecto Apartamentos 6</h3>
          <div class="mb-1 text-body-secondary">12 Nov 2023</div>
          <p class="card-text">
            <strong>Monto Invertido:</strong> 45.000 U$D<br />
            <strong>Ingresos a la fecha:</strong> 2.200 U$D<br />
            <img className="astats" src={Stats} alt="stats"/> 
          </p>
          
        </div>
        <div class="col-auto d-none d-lg-block">
          <img className="img1" src={Img4} alt="img4"/>
        </div>
      </div>
      </Link>
      </div>







    </div>





    </div>
    </>
  );
}

export default MyInvestments
