import React from "react"

const PrivacyPolicy = () => {
  return (
    <div className="container">
      <br /><br />
      <h1>Política de Privacidad de la Plataforma Orion Capital</h1>
      <br />


<p><em>Última actualización: 1/11/2023:12:44am</em></p>
<br />
<p><strong>Introducción</strong></p>

<p>En Orion Capital, valoramos tu privacidad y la seguridad de tus datos personales. 
Esta Política de Privacidad describe cómo recopilamos, utilizamos y compartimos información personal cuando utilizas nuestra aplicación. 
Al utilizar Plataforma Orion Capital, aceptas las prácticas descritas en esta política.</p>

<p><strong>Información que Recopilamos</strong></p>
<ul>
<li><p><em>Información Personal</em>: Cuando te registras o utilizas nuestra aplicación, podemos recopilar información personal como tu nombre, dirección de correo electrónico, número de teléfono, dirección y otra información de contacto.</p></li>
<li><p><em>Información Financiera</em>: Si utilizas funciones relacionadas con inversiones inmobiliarias, podemos recopilar información financiera, como detalles bancarios, información de pagos y otros datos relacionados con las transacciones financieras.</p></li>
<li><p><em>Información de Uso</em>: Registramos información sobre cómo utilizas la aplicación, incluyendo las propiedades o proyectos que ves, las transacciones que realizas y tu interacción con otros usuarios.</p></li>
<li><p><em>Información del Dispositivo</em>: Recopilamos información sobre tu dispositivo, incluyendo el modelo, sistema operativo y otros datos técnicos.</p></li>
</ul>

<p><strong>Cómo Usamos Tu Información</strong></p>
<p>Utilizamos la información que recopilamos para:</p>
<ul>
  <li><p>Proporcionarte los servicios de la aplicación y administrar tu cuenta.</p></li>
  <li><p>Facilitar transacciones y proporcionar asistencia al cliente.</p></li>
  <li><p>Personalizar tu experiencia y ofrecerte contenido relevante.</p></li>
  <li><p>Mantener la seguridad y la integridad de la aplicación.</p></li>
  <li><p>Cumplir con nuestras obligaciones legales.</p></li>
</ul>

<p><strong>Compartir Tu Información</strong></p>
<p>Podemos compartir tu información personal con:</p>

<ul>
  <li><p>Socios de negocios y proveedores de servicios que nos ayudan a operar la aplicación y proporcionar servicios.</p></li>
  <li><p>Autoridades gubernamentales en respuesta a una solicitud legal.</p></li>
  <li><p>Otros usuarios de la aplicación en relación con transacciones o interacciones.</p></li>
</ul>

<p>No venderemos tu información personal a terceros sin tu consentimiento explícito.</p>

<p><strong>Seguridad de Datos</strong></p>
<p>Tomamos medidas para proteger tus datos personales y financieros, pero ten en cuenta que ningún método de transmisión por Internet o almacenamiento electrónico es 100% seguro. Te recomendamos que elijas una contraseña segura y mantengas la confidencialidad de tu información de inicio de sesión.</p>

<p><strong>Tus Derechos y Elecciones</strong></p>
<p>Puedes acceder, corregir o eliminar tu información personal en cualquier momento a través de la configuración de tu cuenta. También puedes darte de baja de comunicaciones de marketing.</p>

<p><strong>Cambios en Esta Política</strong></p>
<p>Nos reservamos el derecho de actualizar o modificar esta Política de Privacidad en cualquier momento. Te notificaremos de cualquier cambio significativo y publicaremos la versión actualizada en la aplicación.</p>

<p><strong>Contacto</strong></p>

<p>Si tienes preguntas o inquietudes sobre esta Política de Privacidad, contáctanos en info@orioncapital.com.py.</p>

<hr/>
<br /><br />

    </div>
  )
}

export default PrivacyPolicy
