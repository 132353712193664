import React from "react"
import { Link } from 'react-router-dom'
import bglogin from "../images/bglogin.png"
import logo from "../images/logo-letme-c-blue.svg"


const Login = () => {
  return (
      <div className="row">
      <div className="col-md-6 d-none d-md-block" style={{ backgroundImage: `url(${bglogin})`, backgroundSize: "cover", height: "100vh" }}>
          {/* Contenido de la columna izquierda (imagen de fondo) */}
        </div>
        <div className="col-md-6 login">
            <div className="container" style={{maxWidth: 500 + 'px'}}>
                <div className="text-center mt-5">
                <img className="logo" src={logo} alt="Logo" />
                </div>
                <span className="iniciodes">
                <h2 className="mt-3">Iniciar Sesión</h2>
                <hr/>
                </span>
                
                <form>
                <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input type="email" className="form-control" id="email" placeholder="Correo electrónico" required />
                </div>
                <div className="form-group">
              <label htmlFor="password">Password:</label>
              <input type="password" className="form-control" id="password" placeholder="Password" required />
                </div>
                <div className="checkbox">
			    	    	<label>
			    	    		<input name="remember" type="checkbox" value="Remember Me" /> Recordar 
			    	    	</label>
			    </div>
                <div className="text-center">
              <Link to="/dashboard" type="submit" className="btn btn-primary">Iniciar Sesión</Link>
                </div>
                <div className=" mt-3">
              <Link to="/">¿No tienes una cuenta? Crea una cuenta aquí</Link>
                </div>
                <div className=" mt-3">
              O inicia sesión con:
              <Link to="/" className="btn btn-danger">Google</Link>
            </div>
          </form>
          </div>
        </div>
      </div>
    
  )
}

export default Login
