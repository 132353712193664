import React from 'react';
import Nav from "./components/Nav"
import { Link } from "react-router-dom"
import { 
  AreaChart,
  Area,
  BarChart, 
  Bar, 
  Cell, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend, 
  ResponsiveContainer,
  LineChart,
  Line,
  PieChart, 
  Pie,
  Label
} from 'recharts'





  const data4 = [
    {
      name: "Ene",
      "Ingresos": 4000,
      "Expensas": 400
    },
    {
      name: "Feb",
      "Ingresos": 4000,
      "Expensas": 400
    },
    {
      name: "Mar",
      "Ingresos": 4000,
      "Expensas": 400
    },
    {
      name: "Abr",
      "Ingresos": 5000,
      "Expensas": 600
    },
    {
      name: "May",
      "Ingresos": 5000,
      "Expensas": 600
    },
    {
      name: "Jun",
      "Ingresos": 5000,
      "Expensas": 600
    },
    {
      name: "Jul",
      "Ingresos": 5000,
      "Expensas": 600
    },
    {
      name: "Ago",
      "Ingresos": 4000,
      "Expensas": 400
    },
    {
      name: "Sep",
      "Ingresos": 4000,
      "Expensas": 400
    },
    {
      name: "Oct",
      "Ingresos": 5000,
      "Expensas": 600
    },
    {
      name: "Nov",
      "Ingresos": 5900,
      "Expensas": 650
    },
    {
      name: "Dic",
      "Ingresos": 0,
      "Expensas": 0
    },

  ]





const data = [{ value: 80 }, { value: 20 }]

const COLORS = ['#112c4c', '#ececec'] 

const CustomLabel = ({ viewBox, value }) => (
  <text x={viewBox.cx} y={viewBox.cy} dy={8} textAnchor="middle" fontWeight="600" fontSize="32" fill="#ff9f5e">
    {`${value}%`}
  </text>
);


const Sales = () => {
    return (
      <>
      <Nav activeLink={"Inicio"}/>
      <br /><br /><br /><br />
    <div className="container">
      <div className="row enc">
      <div className="col-md-12 text-center">
        <span><strong>Unidades Disponibles del Proyecto 1</strong></span>
      </div>
      </div>
      <div className="row">

        <div className="col-md-6">
          
            <div className="table1">
            <div><h4><i class="fas fa-angle-left"></i>Noviembre 2023<i class="fas fa-angle-right"></i></h4></div>
        <table class="table">
  <thead>
    <tr>
      <th scope="col">Unidades</th>
      <th scope="col">Precio Lista</th>
      <th scope="col">Cierre Final</th>
      <th scope="col">Contratos</th>
      <th scope="col">Estado</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row"><i class="far fa-check-circle"></i><a href="#">Apto (1)</a></th>
      <td>50.000</td>
      <td>50.000</td>
      <td><a href="#">Contrato 0034</a></td>
      <td>Vendido</td>
    </tr>
    <tr>
      <th scope="row"><i class="far fa-check-circle"></i> <a href="#">Apto (2)</a></th>
      <td>50.000</td>
      <td>50.000</td>
      <td><a href="#">Contrato 0035</a></td>
      <td>Vendido</td>
    </tr>
    <tr>
      <th scope="row"><i class="far fa-check-circle"></i> <a href="#">Apto (3)</a></th>
      <td>50.000</td>
      <td>45.000</td>
      <td><a href="#">Contrato 0036</a></td>
      <td>Vendido</td>
    </tr>
    <tr>
      <th scope="row"><i class="far fa-check-circle"></i> <a href="#">Apto (4)</a></th>
      <td>50.000</td>
      <td>45.000</td>
      <td><a href="#">Contrato 0037</a></td>
      <td>Vendido</td>
    </tr>
    <tr>
      <th scope="row"><i class="far fa-check-circle"></i> <a href="#">Apto (5)</a></th>
      <td>50.000</td>
      <td>50.000</td>
      <td><a href="#">Contrato 0038</a></td>
      <td>Vendido</td>
    </tr>
    <tr>
      <th scope="row"><i class="far fa-check-circle"></i> <a href="#">Apto (6)</a></th>
      <td>50.000</td>
      <td>50.000</td>
      <td><a href="#">Contrato 0039</a></td>
      <td>Vendido</td>
    </tr>
    <tr>
      <th scope="row"><i class="far fa-check-circle"></i> <a href="#">Apto (7)</a></th>
      <td>50.000</td>
      <td>50.000</td>
      <td><a href="#">Contrato 0040</a></td>
      <td>Vendido</td>
    </tr>
    <tr>
      <th scope="row"><i class="far fa-check-circle"></i> <a href="#">Apto (8)</a></th>
      <td>50.000</td>
      <td>50.000</td>
      <td><a href="#">Contrato 0041</a></td>
      <td>Vendido</td>
    </tr>
    <tr>
      <th scope="row"><i class="far fa-check-circle"></i> <a href="#">Apto (9)</a></th>
      <td>50.000</td>
      <td>50.000</td>
      <td><a href="#">Contrato 0042</a></td>
      <td>Vendido</td>
    </tr>
    <tr>
      <th scope="row"><i class="fas fa-circle"></i> <a href="#">Apto (10)</a></th>
      <td>50.000</td>
      <td>0</td>
      <td></td>
      <td><strong>Disponible</strong></td>
    </tr>
    <tr>
      <th scope="row"><i class="fas fa-circle"></i> <a href="#">Apto (11)</a></th>
      <td>50.000</td>
      <td>0</td>
      <td></td>
      <td><strong>Disponible</strong></td>
    </tr>
    <tr>
      <th scope="row"><i class="far fa-check-circle"></i> <a href="#">Apto (12)</a></th>
      <td>50.000</td>
      <td>50.000</td>
      <td><a href="#">Contrato 0045</a></td>
      <td>Vendido</td>
    </tr>
    <tr>
      <th scope="row"><i class="fas fa-circle"></i> <a href="#">Apto (13)</a></th>
      <td>50.000</td>
      <td>0</td>
      <td></td>
      <td><strong>Disponible</strong></td>
    </tr>
    <tr>
      <th scope="row"><i class="far fa-check-circle"></i> <a href="#">Apto (14)</a></th>
      <td>50.000</td>
      <td>50.000</td>
      <td><a href="#">Contrato 0047</a></td>
      <td>Vendido</td>
    </tr>
    <tr>
      <th scope="row"><i class="far fa-check-circle"></i> <a href="#">Apto (15)</a></th>
      <td>50.000</td>
      <td>50.000</td>
      <td><a href="#">Contrato 0048</a></td>
      <td>Vendido</td>
    </tr>
    <tr>
      <th scope="row">Totales: </th>
      <td><strong>750.000</strong></td>
      <td><strong>590.000</strong></td>
      <td></td>
    </tr>
  </tbody>
</table>
</div>
        </div>
        <br /><br />
        <div className="col-md-6">


        <div className="row">
          <div className='col-md-6'>
            <div className='corange'>
            <span>Venta Actual</span>
              <div>
                <span>
                
                U$D 590.000
                </span>
              </div>
            </div>
          </div>
          <br /><br />
          <div className='col-md-6'>
          <div className='cwhite arotop'>
          <span className='ocup'>Venta<br />del Edificio</span>
          <span className='aro'>
    <ResponsiveContainer width="100%" height={150}>
    <PieChart>
      <Pie
        data={data}
        cx="50%"
        cy="50%"
        startAngle={90}
        endAngle={-270}
        innerRadius="60%"
        outerRadius="80%"
        fill="#8884d8"
        dataKey="value"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
        <Label content={<CustomLabel value={data[0].value} />} position="center" />
      </Pie>
    </PieChart>
  </ResponsiveContainer>
  </span>
            </div>
            <br />
          </div>
          <br /><br />
        </div>








        <div className="row">
          <div className='col-md-6'>
            <div className='cdark3'>
            <span>Venta Proyectada</span>
              <div>
                <span>
                
                U$D 740.000
                </span>
              </div>
            </div>
          </div>
          <br /><br />
          <div className='col-md-6'>

          <div className='cdark2'>
            <span>Venta Pendiente</span>
              <div>
                <span>
                
                U$D 150.000
                </span>
              </div>
            </div>

            <br />
          </div>
          <br /><br />
        </div>





        <div className="charts-sales text-center">
            <h5>Monto de venta por més</h5>
            <ResponsiveContainer>
        <BarChart
          width={500}
          height={500}
          data={data4}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          barSize={15}
        >
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="Ingresos" stackId="a" fill="#112c4c" />
  
          
        </BarChart>
      </ResponsiveContainer>
    </div>

        </div>



        
      </div>
      









      {/*<div className='row'>
        
      <div className="col-md-12">
        <br />
        <div className="charts text-center">
            <h5></h5>
            <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={500}
          height={400}
          data={data4}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Area type="monotone" dataKey="Cashflow acum" stackId="1" stroke="#8884d8" fill="#8884d8" />
          <Area type="monotone" dataKey="Cashflow" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
        </AreaChart>
      </ResponsiveContainer>
    </div>
    <br /><br />
        </div>
        </div>*/}







    </div>
      </>
    )
}

export default Sales