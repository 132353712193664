import React from "react"
import Nav from "./components/Nav"
import { Link } from "react-router-dom"
import Img1 from "./images/img1-2.jpg"
import Imgstats from './images/bar-stats.png'

const AvailableProjectsDetails = () => {
    return (
      <>
    <Nav activeLink={"AvailableProjects"}/>
    <br /><br /><br /><br />
<div className="container">
      <div className="row enc">
      <div className="col-md-4">
        <span>Proyecto Apartamentos 1</span>
      </div>
      <div className="col-md-4">
        
      </div>
      <div className="col-md-4">
        <span><strong>Dashboard</strong> &gt; Proyectos Disponibles</span>
      </div>
      </div>



    <div className="row">
        <div className="col-md-6">
            <div className="borde-img">
            <img className="img-fluid" src={Img1} alt="img1" />
            </div>
            
        </div>
        <div className="col-md-6">
            <h4>Detalles</h4>
            <p>Tipo de Desarrollo: Tipo 1<br />
Formato Comercial: Venta/Alquiler<br />
Costo del Proyecto: 5.000.000 U$D<br />
Venta del Proyecto: 9.000.000 U$D<br />
TIR/VAN/ROC/ROE/ROS<br />
Ingresos Previstos: 3.000.000 U$D<br />
Fechas<br />
Plazo<br />
Retorno de Capital: 40%<br />
Retorno Anual: 15%</p>
        </div>
    
    </div>
    <br /><br /> 
    <div className="row">
        
    <div className="col-md-12">
            <div className="borde-img">
            <img className="img-fluid" src={Imgstats} alt="imgstats" />
            </div>
            
        </div>
    </div>

</div>
      </>
    )
}
export default AvailableProjectsDetails